import { useEffect, useMemo, useState } from 'react';
import { breakpoints } from '@etg/wings';
import { css, cx } from '@eti/styles';
import { useSiteContext } from '@eti/providers';
import { constants } from '@eti/utils';

const baseStyles = css`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const getMobileStyles = (image: string) => css`
  background-image: url('${image}');
`;

const getTabletStyles = (image: string) => css`
  @media (min-width: ${breakpoints._768}) {
    background-image: url('${image}');
  }
`;

const getDesktopStyles = (image: string) => css`
  @media (min-width: ${breakpoints._1024}) {
    background-image: url('${image}');
  }
`;

const getImageStyles = (images: string[]) =>
  images.reduce((acc: string[], image) => {
    if (image.includes('800')) {
      return [...acc, getMobileStyles(image)];
    }
    if (image.includes('1200')) {
      return [...acc, getTabletStyles(image)];
    }
    if (image.includes('1600')) {
      return [...acc, getDesktopStyles(image)];
    }
    return acc;
  }, []);

export const backgroundImageTypes = {
  PANEL: 'panel',
  LOCATION: 'panel/locations',
} as const;

type BackgroundImageTypes = (typeof backgroundImageTypes)[keyof typeof backgroundImageTypes];

const IMAGE_SIZES = ['800', '1200', '1600'] as const;
const LOCATION_FALLBACK_IDS = [
  'loc-lon',
  'loc-sto',
  'loc-ist',
  'loc-bkk',
  'loc-cph',
  'loc-par',
  'loc-lis',
  'loc-rom',
  'loc-prg',
  'loc-ath',
] as const;

const getFallbackImageId = () =>
  LOCATION_FALLBACK_IDS[Math.floor(Math.random() * LOCATION_FALLBACK_IDS.length)];

export const useBackgroundImage = (
  imageId?: string | null,
  type: BackgroundImageTypes = backgroundImageTypes.PANEL,
) => {
  const [images, setImages] = useState<string[] | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchImages = async (arg: string) => {
      const imageReferences: string[] = await Promise.all(
        IMAGE_SIZES.map(async (size) => {
          return await import(`@eti/assets/images/${type}/${arg}_${size}.webp`);
        }),
      ).then((result) => result.map((i) => i.default));

      if (isMounted) {
        setImages(imageReferences);
      }
    };

    (async () => {
      if (imageId) {
        try {
          await fetchImages(imageId);
        } catch (err) {
          if (type === backgroundImageTypes.LOCATION) {
            try {
              const fallbackId = getFallbackImageId();

              await fetchImages(fallbackId);
            } catch (error) {
              console.warn(error); // eslint-disable-line no-console
            }
          } else {
            console.warn(err); // eslint-disable-line no-console
          }
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [imageId, type]);

  return images ? { cssClass: cx(baseStyles, ...getImageStyles(images)) } : {};
};

const {
  ADR,
  FLIGHTNETWORK,
  GOTOGATE,
  HOTELIERMART,
  ROME2RIO,
  SOUTHWEST,
  SUBIRTE,
  TRAVELVENTURA,
  TRIPSTACK,
  MYTRIP,
} = constants.brands;
type Brand = (typeof constants.brands)[keyof typeof constants.brands];
const brandsWithSingleSearchBackground: Brand[] = [
  ADR,
  HOTELIERMART,
  ROME2RIO,
  SOUTHWEST,
  SUBIRTE,
  TRAVELVENTURA,
  TRIPSTACK,
];
const brandsWithMultipleSearchBackground: Record<string, number> = {
  [FLIGHTNETWORK]: 3,
  [GOTOGATE]: 3,
  [MYTRIP]: 4,
};

const getImageId = (brand: Brand) => {
  if (brandsWithSingleSearchBackground.includes(brand)) {
    return `staticBrand/${brand}`;
  }

  if (brandsWithMultipleSearchBackground[brand] !== undefined) {
    const randomImageId = Math.floor(Math.random() * brandsWithMultipleSearchBackground[brand] + 1);
    return `${brand}/startpage_${randomImageId}`;
  }

  return `locations/${getFallbackImageId()}`;
};

const SEARCHFORM_IMAGE_SIZES = ['1200', '1600'] as const;

export const useSearchFormBackgroundImage = () => {
  const { brand } = useSiteContext();
  const [images, setImages] = useState<string[] | null>(null);

  const imageId = useMemo(() => getImageId(brand.code), [brand.code]);

  useEffect(() => {
    let isMounted = true;

    const fetchImages = async (arg: string) => {
      const imageReferences: string[] = await Promise.all(
        SEARCHFORM_IMAGE_SIZES.map(async (size) => {
          return await import(`@eti/assets/images/panel/${arg}_${size}.webp`);
        }),
      ).then((result) => result.map((i) => i.default));

      if (isMounted) {
        setImages(imageReferences);
      }
    };

    (async () => {
      try {
        await fetchImages(imageId);
      } catch (err) {
        console.warn(err); // eslint-disable-line no-console
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [imageId]);

  return images ? { cssClass: cx(baseStyles, ...getImageStyles(images)) } : {};
};
